import React from 'react'
import { buildFontFaces } from '@sh24/ui-components'

const FontFaces = ({ fonts, Head }) => (
  <Head>
    {/* eslint-disable react/no-danger */}
    <style dangerouslySetInnerHTML={{
      __html: buildFontFaces(fonts),
    }}
    />
  </Head>
)

export default React.memo(FontFaces)
