export type ManagementAccessTokenData = {
  access_token: string
  expires_in: number
  scope: string
  token_type: string
}

export type EnrollmentData = {
  id?: string
  status?: string
  type?: string
  name?: string
  identifier?: string
  phone_number?: string
  auth_method?: string
  enrolled_at?: string
  last_auth?: string
}

export type PhoneNumberData = {
  countryCode?: string
  phoneNumber?: string
}

export enum ErrorCode {
  'CONSULTATION_BOOKING_RESOURCE_CONFLICT',
  'CONSULTATION_BOOKING_SLOT_TAKEN',
  'MFA_NOT_ENABLED',
}

export type ErrorData = {
  errors: { code?: ErrorCode, message: string }[]
}
