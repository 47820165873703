import {
  Dispatch, SetStateAction, useState, useMemo,
} from 'react'
import { PrescriptionsData } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import { isErrorData } from '../../utils/api-utils'
import fetchPrescriptions from '../../services/accounts/fetch-prescriptions'

const getEmptySetter = <T>(): Dispatch<SetStateAction<T>> => () => {
  // noop
}

export type PrescriptionsContextType = {
  allPrescriptions: PrescriptionsData | null,
  setAllPrescriptions: Dispatch<SetStateAction<PrescriptionsData | null >>,
  errors: ErrorData | null,
  setErrors: Dispatch<SetStateAction<ErrorData | null>>,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  refreshPrescriptions?: () => Promise<void>,
}

export const defaultPrescriptionsContext = {
  allPrescriptions: null,
  setAllPrescriptions: getEmptySetter(),
  errors: null,
  setErrors: getEmptySetter(),
  isLoading: true,
  setIsLoading: getEmptySetter(),
}

export const createPrescriptionsContext = (): PrescriptionsContextType => {
  const [allPrescriptions, setAllPrescriptions] = useState<PrescriptionsData | null>(null)
  const [errors, setErrors] = useState<ErrorData | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const refreshPrescriptions = useMemo(() => async () => {
    setIsLoading(true)
    const fetchedPrescriptions = await fetchPrescriptions()
    if (isErrorData(fetchedPrescriptions)) {
      setErrors(fetchedPrescriptions as ErrorData)
    } else {
      setAllPrescriptions(fetchedPrescriptions as PrescriptionsData)
      setErrors(null)
    }
    setIsLoading(false)
  }, [setIsLoading, setErrors, setAllPrescriptions])

  return useMemo(() => ({
    allPrescriptions,
    setAllPrescriptions,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    refreshPrescriptions,
  }),
  [
    allPrescriptions, setAllPrescriptions,
    errors, setErrors,
    isLoading, setIsLoading,
  ])
}
