import { parsePhoneNumber } from 'libphonenumber-js/mobile'
import emojiRegex from 'emoji-regex'
import additionalPhoneCountryCodesForCountry from '../../../utils/additional-phone-country-codes-for-country'
import validatePostcode from '../../../utils/validate-postcode'

const REGEXP = {
  eircode: /^[A-Za-z]\d[\dW] [A-Za-z\d]{4}$|^[A-Za-z]\d[\dW][A-Za-z\d]{4}$/,
  name: /^[^\d@]+$/i,
  emoji: emojiRegex(),
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phonenumber: /(01|07|44|\+44)\s?\d{2,}\s*\d{2,}\s?\d{2,}/,
}

const getDOBParts = (value) => {
  const year = parseInt(value.yyyy, 10)
  const month = parseInt(value.mm, 10)
  const day = parseInt(value.dd, 10)
  return {
    year,
    month,
    day,
  }
}

/* eslint-disable no-underscore-dangle */
export function agreeTerms(value) { return value && value.includes('agree') }

export function doesNotContainEmoji(value) { return !value.match(REGEXP.emoji) }

export function name(value) { return !!value.match(REGEXP.name) && doesNotContainEmoji(value) }

export function doesNotContainPhoneNumber(value) { return !value.match(REGEXP.phonenumber) }

export function isMobilePhoneNumber(value, countryCode) {
  try {
    const countryCodes = [countryCode, ...additionalPhoneCountryCodesForCountry(countryCode)]
    const parsedNumber = parsePhoneNumber(value.input, value.countryCode)
    return countryCodes.includes(parsedNumber.country) && parsedNumber.isValid() && (parsedNumber.getType() === 'MOBILE')
  } catch {
    return false
  }
}

export function postcode(value) {
  return validatePostcode(value.trim())
}

export function eircode(value) { return !!value.trim().match(REGEXP.eircode) }

export function inPast(value) { return new Date(value).getTime() < Date.now() }

export function inFuture(value) { return new Date(value).getTime() > Date.now() }

export function earlierThanDate(value, target) {
  return new Date(value).getTime() < new Date(this._target[target]).getTime()
}

export function laterThanDate(value, target) {
  return new Date(value).getTime() > new Date(this._target[target]).getTime()
}

export function isValidDateOfBirth(value) {
  const { year, month, day } = getDOBParts(value)

  // let individual field validation take precedence.
  if (!day || !month || !year) return true

  const date = new Date(year, month - 1, day)
  const validDate = date.getFullYear() === year
    && date.getMonth() === month - 1
    && date.getDate() === day

  return validDate ? inPast(date) : false
}

export function betweenInclusive(val, min, max) {
  return (this.greaterThanEqualTo(val, min)
    && this.lessThanEqualTo(val, max))
}

export function lessThanEqualToField(value, fieldId) {
  return this.lessThanEqualTo(value, this._target[fieldId])
}

export function entriesGreaterThan(value, min) {
  return typeof value === 'object' ? Object.keys(value).length > min : false
}

export function isEmail(value) { return !!value.match(REGEXP.email) && doesNotContainEmoji(value) }

export function checkBoxSelectionRequired(value) {
  return value.constructor === Array && value.length > 0
}

export function isExclusiveSelection(value, exclusiveValue) {
  return value.constructor === Array
    && (
      (value.length === 1 && value[0] === exclusiveValue)
      || value.includes(exclusiveValue) === false
    )
}

const checkNumber = (val) => !Number.isNaN(parseFloat(val)) && Number.isFinite(parseFloat(val))

export function greaterThan(val, param) {
  return checkNumber(val) && checkNumber(param) && (parseFloat(val) > parseFloat(param))
}

export function greaterThanEqualTo(val, param) {
  return checkNumber(val) && checkNumber(param) && (parseFloat(val) >= parseFloat(param))
}

export function lessThan(val, param) {
  return checkNumber(val) && checkNumber(param) && (parseFloat(val) < parseFloat(param))
}

export function lessThanEqualTo(val, param) {
  return checkNumber(val) && checkNumber(param) && (parseFloat(val) <= parseFloat(param))
}

export function basketHasMainItems(basket) {
  return !!basket?.some((item) => !item.isExtra)
}

export function basketHasPrimaryItems(basket) {
  return !!basket?.some((item) => item.isPrimary)
}

/* eslint-enable no-underscore-dangle */
