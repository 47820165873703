function formatPostcode(value) {
  const postcodeWithoutWhitespace = value.replace(/\s+/g, '').toUpperCase()
  const inwardCode = postcodeWithoutWhitespace.slice(-3)
  const outwardCode = postcodeWithoutWhitespace.slice(0, -3).trim()
  const formattedPostcode = `${outwardCode} ${inwardCode}`

  return formattedPostcode
}

export default formatPostcode
