/*
  A generic recursive transform which will apply the `transformElement` function
  against every array element and object value in the supplied data element.
 */

const transformData = (
  data: unknown,
  transformElement: (element: unknown) => unknown,
): unknown => {
  if (Array.isArray(data)) {
    return data.map((element) => transformData(element, transformElement))
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        transformData(value, transformElement),
      ]),
    )
  }
  return transformElement(data)
}

const recursiveTransform = (transformElement: (data: unknown) => unknown) => (
  data: unknown,
): unknown => transformData(data, transformElement)

export default recursiveTransform
