import { NumberedList } from '@sh24/ui-components'
import { useContentContext } from '../../../contexts/content-context'

const variants = {
  large: NumberedList,
  standard: ({ children }) => <ol>{children}</ol>,
}

const OL = ({ children }) => {
  const ctx = useContentContext()
  const Component = variants[ctx?.numberStyle] || variants.standard
  const align = ctx?.textAlign || 'left'

  return (
    <Component align={align}>
      {children}
    </Component>
  )
}

export default OL
