import { getAlgoliaResults } from '@algolia/autocomplete-js'
import searchClient from './clients/search'

/**
 * createAlgoliaSource implements the algolia autocomplete plugin interface.
 *
 * This allows us to configure instances of the AutoComplete component
 * with different source data by defining an algoliaSource plugin.
 *
 * Read the docs:
 * https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/plugins/#building-your-own-plugin
 */
const createAlgoliaSource = ({
  sourceId,
  indexes,
  getItemUrl,
}) => ({
  getSources() {
    return [
      {
        sourceId,
        getItems: ({ query }) => getAlgoliaResults({
          searchClient,
          queries: indexes.map(({ indexName, params }) => (
            {
              query,
              indexName,
              params: params || null,
            }
          )),
        }),
        getItemUrl: getItemUrl || null,
      },
    ]
  },
})

export default createAlgoliaSource
