import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import ReplacementToken from '../../components/ReplacementToken/replacement-token'
import renderNode from './render-node'

export const replacementTokenInlineNodesMap = {
  replacementToken: (target) => (<ReplacementToken {...target} />),
}

export const replacementTokenBlockNodesMap = {
  replacementToken: (target) => (<ReplacementToken {...target} asBlock />),
}

const replacementTokenOptions = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: renderNode(replacementTokenInlineNodesMap),
    [BLOCKS.EMBEDDED_ENTRY]: renderNode(replacementTokenBlockNodesMap),
  },
}

export default replacementTokenOptions
