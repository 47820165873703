const lookupTranslations = ({ translations, lookup }) => {
  const filteredEntries = Object
    .entries(translations)
    .filter(([key]) => key.startsWith(lookup))
    .map(([key, value]) => [key.replace(lookup, ''), value])

  return Object.fromEntries(filteredEntries)
}

export default lookupTranslations
