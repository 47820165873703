import { Team } from '@sh24/ui-components'
import Image from '../../Image/image'
import useTranslations from '../../../utils/use-translations'

const TeamModule = ({
  id,
  title,
  highlight,
  members,
}) => {
  const translations = useTranslations()
  const processedMembers = members.map((member) => ({
    ...member,
    yearsExperience: member.yearsExperience
      ? `${member.yearsExperience}+ ${translations['person.yearsExperience']}`
      : null,
    image: (
      <Image
        image={member.image}
        maxHeight={60}
        maxWidth={60}
        layout="intrinsic"
      />
    ),
  }))

  return (
    <Team
      key={id}
      title={title}
      highlight={highlight}
      members={processedMembers}
    />
  )
}

export default TeamModule
