import styled from 'styled-components'
import { Widget } from '@typeform/embed-react'

const TypeformEmbedContainer = styled.div`
  ${({ theme }) => `
    margin-top: ${theme?.spacing?.md};
    margin-bottom: ${theme?.spacing?.md};
  `}
`

const TypeformEmbedModule = ({
  typeformId, accessibleTitle,
}) => (
  <TypeformEmbedContainer>
    <Widget
      id={typeformId}
      style={{ width: '100%', height: '500px' }}
      hideHeaders
      opacity={0}
      iframeProps={{ title: accessibleTitle }}
    />
  </TypeformEmbedContainer>
)

export default TypeformEmbedModule
