import { useEffect } from 'react'

/**
 * useCloseKeyboardOnScroll closes the keyboard
 * when a user scrolls on a mobile device.
 *
 * Read the docs:
 * https://www.algolia.com/doc/ui-libraries/autocomplete/guides/creating-a-renderer/#mirroring-a-native-mobile-experience
 */
const useCloseKeyboardOnScroll = ({
  formRef, panelRef, inputRef, getEnvironmentProps,
}) => {
  useEffect(() => {
    if (!(formRef.current && panelRef.current && inputRef.current)) {
      return null
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      panelElement: panelRef.current,
      inputElement: inputRef.current,
    })

    window.addEventListener('touchstart', onTouchStart)
    window.addEventListener('touchmove', onTouchMove)

    return () => {
      window.removeEventListener('touchstart', onTouchStart)
      window.removeEventListener('touchmove', onTouchMove)
    }
  }, [getEnvironmentProps, formRef, panelRef, inputRef])
}

export default useCloseKeyboardOnScroll
