import { PrepSubscriptionData } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import { errorMessage } from '../../utils/api-utils'
import transformDates from '../../utils/date_parser'

const fetchPrepSubscription = async (): Promise<PrepSubscriptionData|ErrorData> => {
  try {
    const response = await fetch('/api/v2/account/prep-subscription', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    return transformDates(await response.json()) as PrepSubscriptionData
  } catch (error) {
    return { errors: [{ message: errorMessage(error) }] }
  }
}

export default fetchPrepSubscription
