import { PrepSubscription } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import { errorMessage } from '../../utils/api-utils'

const closePrepSubscription = async (prepSubscriptionId: number): Promise<PrepSubscription|ErrorData> => {
  try {
    const response = await fetch('/api/v2/account/close-prep-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ prepSubscriptionId }),
    })

    return await response.json()
  } catch (error) {
    return { errors: [{ message: errorMessage(error) }] }
  }
}

export default closePrepSubscription
