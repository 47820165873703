import { useRouter } from 'next/router'
import { Content } from '@sh24/ui-components'
import { useMemo } from 'react'
import documentToReactComponents from '../../../utils/document-to-react-components'
import { usePageContext } from '../../../contexts/page-context'
import ContentContext from '../../../contexts/content-context'
import useTranslations from '../../../utils/use-translations'
import { formatDate } from '../../../utils/helpers'

const ContentModule = ({
  id,
  content,
  width,
  textAlign,
  showLastUpdated,
  listStyle,
  numberStyle,
}) => {
  const { locale } = useRouter()
  const translations = useTranslations()
  const page = usePageContext()
  const document = documentToReactComponents(content)
  const lastUpdatedPrefix = translations['page.lastUpdated'] || ''
  const lastUpdated = formatDate({ dateString: page.lastUpdated, locale })
  const contextProviderValue = useMemo(() => ({
    textAlign,
    listStyle,
    numberStyle,
  }), [
    textAlign,
    listStyle,
    numberStyle,
  ])

  return (
    <ContentContext.Provider value={contextProviderValue}>
      <Content
        key={id}
        width={width}
        textAlign={textAlign}
        content={document}
        showLastUpdated={showLastUpdated}
        lastUpdated={`${lastUpdatedPrefix} ${lastUpdated}`}
      />
    </ContentContext.Provider>
  )
}

export default ContentModule
