import styled from 'styled-components'
import YouTube from 'react-youtube'

const VideoEmbedContainer = styled.div`
  ${({ theme }) => `
    margin-top: ${theme?.spacing?.md};
    margin-bottom: ${theme?.spacing?.md};
  `}
`

const VideoContainer = styled.div`
  .videoEmbed {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
  }

  .videoEmbed iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`

const VideoEmbed = ({
  videoId,
  locale = 'en-GB',
}) => (
  <VideoEmbedContainer>
    <VideoContainer>
      <YouTube
        containerClassName="videoEmbed"
        videoId={videoId}
        opts={{
          host: 'https://www.youtube-nocookie.com',
          playerVars: {
            modestbranding: 0,
            cc_lang_pref: locale,
            hl: locale,
            cc_load_policy: 1,
            controls: 1,
            rel: 0,
          },
        }}
      />
    </VideoContainer>
  </VideoEmbedContainer>
)

export default VideoEmbed
