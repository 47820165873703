import Link from 'next/link'
import styled from 'styled-components'
import { ExternalLink, Icon } from '@sh24/ui-components'

const Anchor = styled.a`
  display: inline-flex;
  gap: ${({ theme }) => theme?.spacing?.sm};
`

const renderLinkContent = ({
  iconName,
  iconPosition,
  roundIcon,
  text,
}) => (
  <>
    {iconName && iconPosition === 'left' && <Icon name={iconName} round={roundIcon} testId={iconName} />}
    {text}
    {iconName && iconPosition === 'right' && <Icon name={iconName} round={roundIcon} testId={iconName} />}
  </>
)

const InlineLink = ({
  url,
  text,
  noFollow = false,
  newTab = false,
  external = false,
  iconName = '',
  roundIcon = false,
  iconPosition = 'right',
  ariaLabel = null,
  context = null,
}) => {
  const classNames = ['link']
  if (context) classNames.push(`${context}-link`)

  if (external) {
    return (
      <ExternalLink
        href={url}
        nofollow={noFollow}
        newTab={newTab}
        className={classNames.join(' ')}
        iconName={iconName}
      >
        {renderLinkContent({
          iconName, iconPosition, roundIcon, text,
        })}
      </ExternalLink>
    )
  }

  const rels = ['noopener']
  if (noFollow) rels.push('nofollow')
  if (newTab) rels.push('noreferrer')

  return (
    <Link href={url} passHref prefetch={false}>
      <Anchor
        rel={rels.join(' ')}
        target={newTab ? '_blank' : null}
        aria-label={ariaLabel}
        className={classNames.join(' ')}
      >
        {renderLinkContent({
          iconName, iconPosition, roundIcon, text,
        })}
      </Anchor>
    </Link>
  )
}

export default InlineLink
