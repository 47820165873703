import { ResultsData } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import { errorMessage, isErrorData, toErrorObject } from '../../utils/api-utils'
import transformDates from '../../utils/date_parser'

const isResultsData = (data: unknown): data is ResultsData => (
  !!data && (typeof data === 'object') && ('authIdentity' in data)
)

const fetchResults = async (): Promise<ResultsData|ErrorData> => {
  try {
    const response = await fetch('/api/v2/account/results', {
      headers: {
        Accept: 'application/json',
      },
    })

    const { status } = response
    const data = transformDates(await response.json())

    if (status === 200 && isResultsData(data)) {
      const orders = data.orders ?? []
      return {
        ...data,
        orders,
      }
    }

    if (isErrorData(data)) {
      return { errors: data.errors.map(toErrorObject) }
    }

    return { errors: [{ message: errorMessage(data) }] }
  } catch (error) {
    return { errors: [{ message: errorMessage(error) }] }
  }
}

export default fetchResults
