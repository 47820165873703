import { ContentImage } from '@sh24/ui-components'
import Image from '../Image/image'

const EmbeddedImage = ({
  image,
  mobileWidth = 'full',
  mobilePosition = 'left',
  desktopWidth = 'full',
  desktopPosition = 'left',
  caption,
}) => {
  if (!image) return null
  const imageComp = <Image image={image} maxHeight={640} maxWidth={640} quality="75" />

  return (
    (
      <ContentImage
        image={imageComp}
        mobileWidth={mobileWidth}
        mobilePosition={mobilePosition}
        desktopWidth={desktopWidth}
        desktopPosition={desktopPosition}
        caption={caption || null}
      />
    )
  )
}

export default EmbeddedImage
