import Head from 'next/head'
import {
  Header,
  Footer,
  Page,
} from '@sh24/ui-components'
import menuToReactComponents from '../utils/menu-to-react-components'
import documentToReactComponents from '../utils/document-to-react-components'
import { useAppContext } from '../contexts/app-context'
import Image from '../components/Image/image'
import Search from '../components/Search/search'
import SiteNames from './site-names'
import ResetCookieConsent from '../components/CookieConsent/reset-cookie-consent'
import AccountMenu from '../components/AccountMenu/account-menu'
import useSessions from '../utils/use-sessions'

const DefaultLayout = ({ site, searchConfig, children }) => {
  const { appContext } = useAppContext()
  const {
    mainMenu,
    loggedInAccountMenu,
    loggedOutAccountMenu,
    footerEndorsement,
    footerLogos,
    footerMenus,
    social,
    translations,
    preview,
  } = appContext
  const footerMenusWithLinks = footerMenus?.length > 0
    ? footerMenus.map((menu) => menuToReactComponents(menu))
    : null

  if (footerMenusWithLinks && footerMenusWithLinks.length) {
    footerMenusWithLinks[footerMenusWithLinks.length - 1].items.push({
      id: 'manage-cookie-consent-btn',
      link: <ResetCookieConsent />,
    })
  }

  const mainMenuWithLinks = menuToReactComponents(mainMenu)
  const accountMenuWithLinks = useSessions() ? AccountMenu({
    translations,
    loggedInAccountMenuWithLinks: loggedInAccountMenu
      ? menuToReactComponents(loggedInAccountMenu)
      : null,
    loggedOutAccountMenuWithLinks: loggedOutAccountMenu
      ? menuToReactComponents(loggedOutAccountMenu)
      : null,
  }) : null

  const SearchComponent = searchConfig ? <Search config={searchConfig} /> : null

  const footerLogoImages = footerLogos ? footerLogos.map((logo) => (
    <Image
      future
      key={logo.id}
      image={logo}
      maxWidth={270}
      maxHeight={270}
      priority
      layout="intrinsic"
      alt={logo.description || ''}
    />
  )) : null

  const footerEndorsementProps = {
    image: footerEndorsement?.image ? (
      <Image
        image={footerEndorsement.image}
        maxWidth={220}
        maxHeight={80}
        priority
        layout="intrinsic"
        alt={footerEndorsement.image.description || ''}
      />
    ) : null,
    content: footerEndorsement?.content
      ? documentToReactComponents(footerEndorsement.content)
      : null,
  }

  return (
    <Page preview={preview}>
      <Head>
        <link rel="icon" href={`/${process.env.NEXT_PUBLIC_SITE}_favicon.ico`} />
      </Head>

      <Header
        siteName={site}
        homePath="/"
        homeAriaLabel={`${SiteNames[site]} ${translations?.returnHome}`}
        menu={mainMenuWithLinks}
        Search={SearchComponent}
        menuOpenText="Menu"
        menuCloseText="Close"
        skipToMainContentLinkText={translations?.skipToMainContentLinkText}
        accountMenu={accountMenuWithLinks}
      />
      <Page.Main>
        {children}
      </Page.Main>
      <Footer
        endorsement={footerEndorsementProps}
        menus={footerMenusWithLinks}
        logos={footerLogoImages}
        social={social}
      />
    </Page>
  )
}

export default DefaultLayout
