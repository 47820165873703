import { PrescriptionsData } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import { errorMessage } from '../../utils/api-utils'
import transformDates from '../../utils/date_parser'

const fetchPrescriptions = async (): Promise<PrescriptionsData|ErrorData> => {
  try {
    const response = await fetch('/api/v2/account/prescriptions', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    return transformDates(await response.json()) as PrescriptionsData
  } catch (error) {
    return { errors: [{ message: errorMessage(error) }] }
  }
}

export default fetchPrescriptions
