import styled from 'styled-components'
import { ReactNode, useMemo } from 'react'
import useTranslations from '../../utils/use-translations'
import { useRichTextDocumentContext } from '../../contexts/rich-text-document-context'
import exceptionNotifier from '../../services/exception-notifier/client'
import { useAppContext } from '../../contexts/app-context'

type AppContext = {
  preview?: boolean;
}

export class TokenReplacementError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'TokenReplacementError'
  }
}

const raiseError = (message: string) => {
  const error = new TokenReplacementError(message)
  exceptionNotifier.notify(error)
  throw error
}

const TokenSuccess = styled.span`
  background-color: lightgreen;
`

const TokenError = styled.span`
  background-color: lightcoral;
`

const getReplacementFunctions = (preview: boolean) => {
  if (preview) {
    return {
      ContextValueMissingError: ({ contextKey }: { contextKey: string }) => (
        <TokenError>{`{${contextKey}}`}</TokenError>
      ),
      TranslationMissingError: ({ translationKey }: { translationKey: string }) => (
        <TokenError>{`{${translationKey}}`}</TokenError>
      ),
      Success: ({ children = undefined }: { children?: ReactNode | undefined }) => (
        <TokenSuccess>{children}</TokenSuccess>
      ),
    }
  }
  return {
    ContextValueMissingError: ({ contextKey }: { contextKey: string }) => {
      raiseError(`Missing context value for key '${contextKey}'`)
      return <></>
    },
    TranslationMissingError: ({ translationKey }: { translationKey: string }) => {
      raiseError(`No translation found for '${translationKey}'`)
      return <></>
    },
    Success: ({ children = undefined }: { children?: ReactNode | undefined }) => (
      <>{children}</>
    ),
  }
}

const ReplacementToken = ({
  useTextFrom,
  contextKey,
  translationPath,
  asBlock = false,
}: {
  useTextFrom: 'contextValue' | 'translation',
  contextKey: string,
  translationPath: string,
  asBlock?: boolean,
}) => {
  const { preview } = (useAppContext() as {appContext: AppContext}).appContext
  const { ContextValueMissingError, TranslationMissingError, Success } = useMemo(
    () => getReplacementFunctions(Boolean(preview)),
    [preview],
  )

  const translations = useTranslations()
  const context = useRichTextDocumentContext()

  const contextValue = context[contextKey]

  if (!contextValue) {
    return <ContextValueMissingError contextKey={contextKey} />
  }

  if (useTextFrom === 'contextValue') {
    return <Success>{contextValue}</Success>
  }

  const fullTranslationPath = `${translationPath}.${contextKey}.${contextValue}`
  const replacementText = translations[fullTranslationPath]

  if (!replacementText) {
    return <TranslationMissingError translationKey={fullTranslationPath} />
  }

  return asBlock ? <p><Success>{replacementText}</Success></p> : <Success>{replacementText}</Success>
}

export default ReplacementToken
