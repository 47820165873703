export default (text) => {
  const breaks = (text.match(/\n/g) || []).length
  if (!breaks) return text

  return text
    .split('\n')
    .filter((item) => item)
    .reduce((children, textSegment, index) => (
      [...children, index > 0 && <br key={textSegment} />, textSegment]
    ), [])
    .filter((item) => item)
}
