import {
  useState, useMemo,
} from 'react'
import { PrepSubscriptionData } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import { isErrorData } from '../../utils/api-utils'
import fetchPrepSubscription from '../../services/accounts/fetch-prep-subscription'

export type PrepSubscriptionContextType = {
  prepSubscription: PrepSubscriptionData | null,
  errors: ErrorData | null,
  isLoading: boolean,
  refreshPrepSubscription?: () => Promise<void>,
  clearPrepSubscription: () => void,
}

export const defaultPrepSubscriptionContext = {
  prepSubscription: null,
  errors: null,
  isLoading: true,
  clearPrepSubscription: () => null,
}

export const createPrepSubscriptionContext = (): PrepSubscriptionContextType => {
  const [prepSubscription, setPrepSubscription] = useState<PrepSubscriptionData | null>(null)
  const [errors, setErrors] = useState<ErrorData | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const refreshPrepSubscription = useMemo(() => async () => {
    setIsLoading(true)
    const fetchedPrepSubscription = await fetchPrepSubscription()
    if (isErrorData(fetchedPrepSubscription)) {
      setErrors(fetchedPrepSubscription as ErrorData)
    } else {
      setPrepSubscription(fetchedPrepSubscription as PrepSubscriptionData)
      setErrors(null)
    }
    setIsLoading(false)
  }, [setIsLoading, setErrors, setPrepSubscription])

  const clearPrepSubscription = () => setPrepSubscription(null)

  return useMemo(() => ({
    prepSubscription,
    errors,
    isLoading,
    refreshPrepSubscription,
    clearPrepSubscription,
  }),
  [
    prepSubscription,
    errors,
    isLoading,
    refreshPrepSubscription,
    clearPrepSubscription,
  ])
}
