import recursiveTransform from './recursive_transform'

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

const transformIfDate = (data: unknown) => {
  if (typeof data === 'string' && isoDateFormat.test(data)) {
    return new Date(data)
  }
  return data
}

const transformDates = recursiveTransform(transformIfDate)

export default transformDates
