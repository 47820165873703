import Script from 'next/script'
import gtmTags from './gtm-tags'

/* eslint-disable react/no-danger */
const GTMScriptTag = ({
  GTM_TRACKING_ID,
  GTM_AUTH_TOKEN,
  GTM_PREVIEW_ENVIRONMENT_ID,
}) => {
  const { script } = gtmTags({ GTM_TRACKING_ID, GTM_PREVIEW_ENVIRONMENT_ID, GTM_AUTH_TOKEN })

  return (
    /*
      If you change the value of __html then you must also update the sha-256 value in
      contentSecurityPolicyValue in next.config.js to reflect the new contents of the script.
      We're forced to use this slightly old-school approach since it's difficult to supply a nonce
      to a statically generated (and potentially cached) page.
    */
    <>
      <Script
        id="initialise-data-layer"
        type="text/javascript"
        src="/initialise-datalayer.js"
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: script,
        }}
      />
    </>
  )
}
/* eslint-enable react/no-danger */

export default GTMScriptTag
