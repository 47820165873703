import { PillList } from '@sh24/ui-components'
import { useContentContext } from '../../../contexts/content-context'

const variants = {
  pill: PillList,
  standard: ({ children }) => <ul>{children}</ul>,
}

const UL = ({ children }) => {
  const ctx = useContentContext()
  const Component = variants[ctx?.listStyle] || variants.standard
  const align = ctx?.textAlign || 'left'

  return (
    <Component align={align}>
      {children}
    </Component>
  )
}

export default UL
