import { Button, ErrorMessages } from '@sh24/ui-components'
import React, { useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { PrepSubscription } from '@sh24/admin-api-js'
import closePrepSubscription from '../../../services/accounts/close-prep-subscription'
import { isErrorData } from '../../../utils/api-utils'
import { ErrorData } from '../../../types/api'
import useTranslations from '../../../utils/use-translations'
import AccountContext from '../../../contexts/account-context'

interface CloseSubscriptionButtonProps {
  prepSubscriptionId: number
  displayText: string
  btnVariation: string
}

const CloseSubscriptionButton = ({ prepSubscriptionId, displayText, btnVariation }: CloseSubscriptionButtonProps) => {
  const [disabled, setDisabled] = useState<boolean>(false)
  const [errors, setErrors] = useState<Array<string>>([])

  const router = useRouter()
  const translations = useTranslations()

  const loadingText = translations['prepDashboard.closeSubscription.closingSubscriptionLoadingText']

  const { clearPrepSubscription } = useContext(AccountContext).prepSubscriptionContext

  const onCloseSubscriptionAction = async () => {
    setErrors([])
    setDisabled(true)

    const closeSubscriptionData = await closePrepSubscription(prepSubscriptionId) as PrepSubscription | ErrorData

    if (isErrorData(closeSubscriptionData)) {
      setErrors([translations['prepDashboard.closeSubscription.errorText']])
      setDisabled(false)
    } else {
      clearPrepSubscription()
      await router.push('/account/prep/close-confirm')
    }
  }

  return (
    <>
      {errors.length > 0 && (<ErrorMessages id="error" errors={errors} />)}

      <Button
        variation={btnVariation}
        text={disabled ? loadingText : displayText}
        onClick={onCloseSubscriptionAction}
        disabled={disabled}
        fullWidth
      />
    </>
  )
}

export default CloseSubscriptionButton
