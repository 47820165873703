import {
  Button,
  BasicModal,
} from '@sh24/ui-components'

import { withCookies } from 'react-cookie'
import { useAppContext } from '../../contexts/app-context'
import documentToReactComponents from '../../utils/document-to-react-components'
import lookupTranslations from '../../utils/lookup-translations'

export const COOKIES_CONSENT_COOKIE = 'cookie_consent'
export const COOKIES_ACCEPTED = 'accepted'
export const COOKIES_REJECTED = 'rejected'
const COOKIE_EXPIRY_DAYS = 365

const CookieConsent = ({ cookies }) => {
  const { appContext } = useAppContext()
  const { translations, cookieConsentContent } = appContext
  const cookieTranslations = lookupTranslations({ translations, lookup: 'cookieConsent.' })

  const setConsent = (choice) => {
    cookies.set(COOKIES_CONSENT_COOKIE, choice, {
      maxAge: (60 * 60 * 24 * COOKIE_EXPIRY_DAYS),
      path: '/',
    })
  }

  const confirmCta = (
    <Button text={cookieTranslations.accept} onClick={() => setConsent(COOKIES_ACCEPTED)} />
  )

  const rejectCta = (
    <Button text={cookieTranslations.reject} onClick={() => setConsent(COOKIES_REJECTED)} variation="secondary" />
  )

  return (
    <BasicModal
      content={documentToReactComponents(cookieConsentContent)}
      confirmCta={confirmCta}
      rejectCta={rejectCta}
    />
  )
}

export default withCookies(CookieConsent)
