import { useState } from 'react'
import styled from 'styled-components'
import { TextField, Button } from '@sh24/ui-components'
import { name as isName, isEmail } from '../../../order-journeys/_v2/validation/validation-functions'
import useTranslations from '../../../utils/use-translations'

const RightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FrontformEmbedModule = ({ frontUrl }) => {
  if (!frontUrl) return null

  const translations = useTranslations()
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const handleChange = (e) => {
    setErrors({
      ...errors,
      [e.target.id]: [],
    })

    setState({
      ...state,
      [e.target.id]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    setSubmitting(true)
    const validName = isName(state.name || '')
    const validEmail = isEmail(state.email || '')
    const newErrs = {}
    if (!validName) newErrs.name = [translations['errors.nameRequired']]
    if (!validEmail) newErrs.email = [translations['errors.email']]
    setErrors(newErrs)

    if (!validName || !validEmail) {
      e.preventDefault()
      setSubmitting(false)
    }
  }

  return (
    <div className="mt-md mb-md">
      <form
        method="POST"
        onSubmit={handleSubmit}
        action={frontUrl}
        encType="multipart/form-data"
        acceptCharset="utf-8"
        noValidate
      >
        <TextField
          id="name"
          name="name"
          label={translations['field.name']}
          value={state.name || ''}
          onChange={handleChange}
          errors={errors.name}
        />
        <TextField
          id="email"
          name="email"
          type="email"
          label={translations['field.email']}
          value={state.email || ''}
          onChange={handleChange}
          errors={errors.email}
        />
        <input type="hidden" name="subject" value="Employee discount request" />
        <input type="hidden" name="body" value={`${state.name} has requested an employee discount code`} />

        <RightAlign>
          <Button
            text={translations['button.submit']}
            type="submit"
            loading={submitting}
            disabled={submitting}
          />
        </RightAlign>
      </form>
    </div>
  )
}

export default FrontformEmbedModule
