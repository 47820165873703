import { createContext } from 'react'
import { User } from '../types/auth'

export type SessionContextType = {
  user: User|null
  setUser: ((user: User|null) => void)|null
  sessionLoaded: boolean
  setSessionLoaded: ((sessionLoaded: boolean) => void)|null
  refreshUser: (() => void)|null
}

const SessionContext = createContext<SessionContextType>(
  {
    user: null,
    setUser: null,
    sessionLoaded: false,
    setSessionLoaded: null,
    refreshUser: null,
  },
)

export default SessionContext
