export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_TRACKING_ID
export const GTM_PREVIEW_ENVIRONMENT_ID = process.env.NEXT_PUBLIC_GTM_PREVIEW_ENVIRONMENT_ID
export const GTM_AUTH_TOKEN = process.env.NEXT_PUBLIC_GTM_AUTH_TOKEN

/* eslint-disable camelcase */
export const gtmEvent = ({
  event,
  action,
  category,
  label,
  value,
  non_interaction = false,
  data = {},
}) => {
  window?.dataLayer?.push({
    event,
    event_category: category,
    event_action: action,
    event_label: label,
    event_value: value,
    non_interaction,
    ...data,
  })
}
/* eslint-enable camelcase */

export const performance = {
  TTFB: 'Time to First Byte (ms)',
  FCP: 'First Contentful Paint (ms)',
  LCP: 'Largest Contentful Paint (ms)',
  FID: 'First Input Delay (ms)',
  CLS: 'Cumulative Layout Shift (score)',
  'Next.js-hydration': 'Next.js-hydration (ms)',
  'Next.js-route-change-to-render': 'Next.js-route-change-to-render (ms)',
  'Next.js-render': 'Next.js-render (ms)',
}

export const events = {
  SEARCH_CLICK: {
    event: 'autocomplete',
    category: 'Search result',
    action: 'click',
  },
  SEARCH_ACTIVE: {
    event: 'autocomplete',
    category: 'Search result',
    action: 'active',
  },
}
