import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    const { notifier, context } = this.props
    if (notifier) {
      notifier.notify({
        error,
        context,
        params: {
          info,
        },
      })
    }
  }

  render() {
    const { hasError } = this.state
    const { children, ErrorComponent } = this.props
    if (hasError) {
      return ErrorComponent || null
    }
    return children
  }
}
