import { Callout, ButtonLink } from '@sh24/ui-components'
import { documentToReactComponents as contentfulDTRC } from '@contentful/rich-text-react-renderer'
import InlineLink from '../../InlineLink/inline-link'
import replacementTokenOptions from '../../../utils/document-to-react-component-renderers/replacement-token'

const CalloutModule = ({
  id,
  backgroundColour,
  iconBackgroundColour,
  icon,
  title,
  content,
  link,
  embed = false,
}) => {
  const cta = (link?.url && link?.text)
    ? (
      <ButtonLink variation="text" animation="shiftRight" iconPosition="right">
        <InlineLink {...link} iconName="arrow-right" context="callout" />
      </ButtonLink>
    )
    : null

  const callout = (
    <Callout
      key={id}
      backgroundColour={backgroundColour}
      iconBackgroundColour={iconBackgroundColour}
      icon={icon}
      title={title}
      content={contentfulDTRC(content, replacementTokenOptions)}
      ctaButton={cta}
    />
  )

  if (embed) return callout

  return (
    <div className="row row-centered">
      <div className="col col-12 col-md-6">
        {callout}
      </div>
    </div>
  )
}

export default CalloutModule
