import {
  createContext, Dispatch, SetStateAction, useMemo, useState,
} from 'react'
import getEmptySetter from '../utils/get-empty-setter'
import { createResultsContext, defaultResultsContext, ResultsContextType } from './account-contexts/results-context'
import { createPrescriptionsContext, defaultPrescriptionsContext, PrescriptionsContextType } from './account-contexts/prescriptions-context'
import {
  createPrepSubscriptionContext,
  defaultPrepSubscriptionContext,
  PrepSubscriptionContextType,
} from './account-contexts/prep-subscription-context'
import { createOrdersContext, defaultOrdersContext, OrdersContextType } from './account-contexts/orders-context'

export type AccountContextType = {
  resultsContext: ResultsContextType,
  prescriptionsContext: PrescriptionsContextType,
  ordersContext: OrdersContextType,
  prepSubscriptionContext: PrepSubscriptionContextType,
  orderId: string | null,
  setOrderId: Dispatch<SetStateAction<string | null>>,
}

const AccountContext = createContext<AccountContextType>({
  resultsContext: defaultResultsContext,
  prescriptionsContext: defaultPrescriptionsContext,
  prepSubscriptionContext: defaultPrepSubscriptionContext,
  ordersContext: defaultOrdersContext,
  orderId: null,
  setOrderId: getEmptySetter(),
})

export const createAccountContext = (): AccountContextType => {
  const [orderId, setOrderId] = useState<string | null>(null)
  const resultsContext = createResultsContext()
  const prescriptionsContext = createPrescriptionsContext()
  const prepSubscriptionContext = createPrepSubscriptionContext()
  const ordersContext = createOrdersContext()

  return useMemo(() => ({
    resultsContext,
    prescriptionsContext,
    prepSubscriptionContext,
    ordersContext,
    orderId,
    setOrderId,
  }), [
    resultsContext,
    prescriptionsContext,
    prepSubscriptionContext,
    ordersContext,
    prepSubscriptionContext,
    orderId, setOrderId,
  ])
}

export default AccountContext
