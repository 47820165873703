import InlineLink from '../components/InlineLink/inline-link'
import ContentModule from '../components/Modules/ContentModule/content-module'

const buildLinkComponent = ({ link }) => (
  <InlineLink
    key={link?.id}
    {...link}
    external
    context="menu-item"
    iconName={link.external ? 'external-link' : null}
  />
)

const buildParentMenuText = ({ link }) => (
  <span>{link.text}</span>
)

const menuItemToReactComponents = (menuItem) => ({
  ...menuItem,
  link: menuItem.link?.url ? buildLinkComponent(menuItem) : buildParentMenuText(menuItem),
  name: menuItem?.link?.text ?? '',
  items: menuItem.items ? menuItem.items.map(menuItemToReactComponents) : null,
  external: menuItem?.link?.external,
})

const menuToReactComponents = (menu) => {
  if (!menu) return null

  return ({
    ...menu,
    preMenuItems: menu.preMenuItems ? menu.preMenuItems.map(ContentModule) : [],
    items: menu.items ? menu.items.map(menuItemToReactComponents) : null,
    postMenuItems: menu.postMenuItems ? menu.postMenuItems.map(ContentModule) : [],
  })
}

export default menuToReactComponents
