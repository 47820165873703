import {
  Dispatch, SetStateAction, useMemo, useState,
} from 'react'
import { HistoricalOrdersData } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import { isErrorData } from '../../utils/api-utils'
import fetchOrders from '../../services/accounts/fetch-orders'
import getEmptySetter from '../../utils/get-empty-setter'

export type OrdersContextType = {
  orders: HistoricalOrdersData | null,
  setOrders: Dispatch<SetStateAction<HistoricalOrdersData | null>>,
  errors: ErrorData | null,
  setErrors: Dispatch<SetStateAction<ErrorData | null>>,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  refreshOrders?: () => Promise<void>,
}

export const defaultOrdersContext: OrdersContextType = {
  orders: null,
  setOrders: getEmptySetter(),
  errors: null,
  setErrors: getEmptySetter(),
  isLoading: true,
  setIsLoading: getEmptySetter(),

}

export const createOrdersContext = (): OrdersContextType => {
  const [orders, setOrders] = useState<HistoricalOrdersData | null>(null)
  const [errors, setErrors] = useState<ErrorData | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const refreshOrders = useMemo(() => async () => {
    setIsLoading(true)
    const fetchedOrders = await fetchOrders()

    if (isErrorData(fetchedOrders)) {
      setErrors(fetchedOrders as ErrorData)
    } else {
      setOrders(fetchedOrders as HistoricalOrdersData)
      setErrors(null)
    }
    setIsLoading(false)
  }, [setIsLoading, setErrors, setOrders])

  return useMemo(() => ({
    orders,
    setOrders,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    refreshOrders,
  }), [
    orders,
    setOrders,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    refreshOrders,
  ])
}
