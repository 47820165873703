import { HistoricalOrdersData } from '@sh24/admin-api-js'
import { ErrorData } from '../../types/api'
import { errorMessage, toErrorObject, isErrorData } from '../../utils/api-utils'
import transformDates from '../../utils/date_parser'

const isOrdersData = (data: unknown): data is HistoricalOrdersData => (
  !!data && (typeof data === 'object') && ('username' in data)
)

const fetchOrders = async (): Promise<HistoricalOrdersData|ErrorData> => {
  try {
    const response = await fetch('/api/v2/account/orders', {
      headers: {
        Accept: 'application/json',
      },
    })

    const { status } = response
    const data = transformDates(await response.json())

    if (status === 200 && isOrdersData(data)) {
      return data
    }

    if (isErrorData(data)) {
      return { errors: data.errors.map(toErrorObject) }
    }

    return { errors: [{ message: errorMessage(data) }] }
  } catch (error) {
    return { errors: [{ message: errorMessage(error) }] }
  }
}

export default fetchOrders
