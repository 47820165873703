import { withCookies } from 'react-cookie'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import useTranslations from '../../utils/use-translations'
import lookupTranslations from '../../utils/lookup-translations'

import { COOKIES_CONSENT_COOKIE } from './cookie-consent'

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  text-align: left;
  padding: 0;
  font-weight: 700;
  line-height: ${({ theme }) => theme?.spacing?.md};

  span {
    color: ${({ theme }) => theme?.palette?.white};
  }

  &:hover,
  &:focus {
    span {
      background: ${({ theme }) => theme?.palette?.primary};
      color: ${({ theme }) => theme?.palette?.black};
    }
  }
`

const ResetCookieConsent = ({ cookies }) => {
  const translations = useTranslations()
  const cookieTranslations = lookupTranslations({ translations, lookup: 'cookieConsent.' })

  const router = useRouter()

  const removeConsent = () => {
    cookies.set(COOKIES_CONSENT_COOKIE, null, {
      maxAge: -99999,
      path: '/',
    })

    router.reload()
  }

  return (
    <MenuButton onClick={removeConsent}><span>{cookieTranslations?.reset}</span></MenuButton>
  )
}

export default withCookies(ResetCookieConsent)
