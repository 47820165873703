import { ResourcesConfig } from '@aws-amplify/core'

const cognito: () => ResourcesConfig = () => {
  const userPoolId = process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID
  const userPoolClientId = process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_CLIENT_ID

  if (!userPoolId || !userPoolClientId) {
    throw new Error('Cognito environment variables are not set')
  }

  return {
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
        signUpVerificationMethod: 'code',
      },
    },
  }
}

export default cognito
