import { fetchUserAttributes } from 'aws-amplify/auth'
import { User } from '../types/auth'

const setSessionContext = async (
  setUser: (user: User|null) => void,
  setSessionLoaded: (sessionLoaded: true) => true,
) => {
  try {
    const user = await fetchUserAttributes() as User
    setUser(user)
  } catch {
    setUser(null)
  }

  setSessionLoaded(true)
}

export const getRefreshUser = (
  setUser: (user: User|null) => void,
  setSessionLoaded: (sessionLoaded: true) => true,
) => (
  () => setSessionContext(setUser, setSessionLoaded)
)

export default setSessionContext
