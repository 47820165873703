import { PersonSnippet } from '@sh24/ui-components'
import Image from '../../Image/image'
import InlineLink from '../../InlineLink/inline-link'
import useTranslations from '../../../utils/use-translations'

const PersonSnippetModule = ({
  image,
  name,
  shortBio,
  url,
  external,
  role,
}) => {
  const translations = useTranslations()
  const prefixMap = {
    writer: translations.writtenBy,
    reviewer: translations.reviewedBy,
  }

  const imageComponent = (
    <Image
      image={image}
      maxWidth={48}
      maxHeight={48}
      layout="intrinsic"
    />
  )

  const nameComponent = url ? (
    <InlineLink
      text={name}
      url={url}
      external={external}
      nofollow={external}
      newTab={external}
      context="person-snippet"
    />
  ) : name

  return (
    <PersonSnippet
      imageComponent={imageComponent}
      prefix={prefixMap[role]}
      nameComponent={nameComponent}
      shortBio={shortBio}
    />
  )
}

export default PersonSnippetModule
