import styled from 'styled-components'
import AutoComplete from '../Autocomplete/auto-complete'
import searchComponents from '../Autocomplete/search-components'
import algoliaInsightsPlugin from '../../services/search/plugins/algolia-insights-plugin'
import { algoliaGTMPlugin } from '../../services/search/plugins/algolia-gtm-plugin'
import i18nPlugin from '../../services/search/plugins/i18n-plugin'
import createAlgoliaSource from '../../services/search/create-algolia-source'

const SearchContainer = styled.div`
  height: 100%;
`

const Search = ({
  config,
  onClose,
  rounded = false,
  locale = 'en-GB',
}) => {
  const { placeholder, searchGroups } = config

  const sources = searchGroups.map(({ title, indexes }) => createAlgoliaSource({
    sourceId: title,
    indexes,
    getItemUrl: ({ item }) => item.url,
  }))

  return (
    <SearchContainer>
      <AutoComplete
        rounded={rounded}
        placeholder={placeholder}
        components={searchComponents}
        plugins={[
          ...sources,
          i18nPlugin({ locale }),
          algoliaGTMPlugin,
          algoliaInsightsPlugin,
        ]}
        onClose={onClose}
        closeButtonAriaLabel={config.closeSearch}
      />
    </SearchContainer>
  )
}

export default Search
