const resizeRelative = ({
  srcWidth, srcHeight, maxWidth, maxHeight,
}) => {
  const smallestRatio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

  return {
    width: Math.floor(srcWidth * smallestRatio),
    height: Math.floor(srcHeight * smallestRatio),
  }
}

export default resizeRelative
