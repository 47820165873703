const gtmTags = ({ GTM_TRACKING_ID, GTM_AUTH_TOKEN, GTM_PREVIEW_ENVIRONMENT_ID }) => {
  const GTM_AUTH = GTM_AUTH_TOKEN ? `&gtm_auth=${GTM_AUTH_TOKEN}` : ''
  const GTM_PREVIEW = GTM_PREVIEW_ENVIRONMENT_ID ? `&gtm_preview=${GTM_PREVIEW_ENVIRONMENT_ID}` : ''
  const GTM_COOKIES = (GTM_AUTH_TOKEN || GTM_PREVIEW_ENVIRONMENT_ID) ? '&gtm_cookies_win=x' : ''

  const iframeSrc = `https://www.googletagmanager.com/ns.html?id=${GTM_TRACKING_ID}${GTM_AUTH}${GTM_PREVIEW}${GTM_COOKIES}`

  const script = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${GTM_AUTH}${GTM_PREVIEW}${GTM_COOKIES}';
    f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_TRACKING_ID}');
  `

  return {
    iframeSrc,
    script,
  }
}

export default gtmTags
