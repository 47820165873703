import styled from 'styled-components'
import {
  useState, useMemo, useRef,
  useCallback,
} from 'react'
import { createAutocomplete } from '@algolia/autocomplete-core'
import {
  SearchInput, SearchResults, ResultsGroup,
} from '@sh24/ui-components'
import useCloseKeyboardOnScroll from './hooks/use-close-keyboard-on-scroll'
import useTranslations from '../../utils/use-translations'

const AutocompleteContainer = styled.div`
  height: 100%;
`

const Form = styled.form`
  height: 100%;
`

/**
 * The AutoComplete component follows the Algolia guide
 * for implementing a custom renderer. This was necessary to
 * allow us full control over styling and to make the search themeable.
 *
 * Read the docs:
 * https://www.algolia.com/doc/ui-libraries/autocomplete/guides/creating-a-renderer/
 *
 * For the full props that can be passed to the AutoComplete component.
 *
 * Read the createAutocomplete docs:
 * https://www.algolia.com/doc/ui-libraries/autocomplete/api-reference/autocomplete-core/createAutocomplete/
 */
const AutoComplete = (props) => {
  const {
    components,
    onClose,
    closeButtonAriaLabel,
    rounded,
  } = props

  const [autocompleteState, setAutocompleteState] = useState({})

  const autocomplete = useMemo(() => createAutocomplete({
    onStateChange: ({ state }) => {
      setAutocompleteState(state)
    },
    ...props,
  }), [props])

  const inputRef = useRef(null)
  const formRef = useRef(null)
  const panelRef = useRef(null)
  const { getEnvironmentProps } = autocomplete

  const translations = useTranslations()

  useCloseKeyboardOnScroll({
    formRef,
    inputRef,
    panelRef,
    getEnvironmentProps,
  })

  const handleClose = useCallback(() => {
    const { setQuery, setIsOpen, setCollections } = autocomplete
    if (!onClose) {
      setQuery('')
      setIsOpen(false)
      setCollections([])
    } else {
      onClose()
    }
  }, [onClose, autocomplete])

  const resultsCount = () => {
    if (autocompleteState.query) {
      const count = autocompleteState?.collections
        ?.map(({ items }) => items.length)
        ?.reduce((partialSum, length) => partialSum + length, 0) || 0
      let message

      switch (count) {
        case 0:
          message = translations['search.noResults']
          break
        case 1:
          message = translations['search.singleResultFound']
          break
        default:
          message = `${count} ${translations['search.multipleResultsFound']}`
      }

      return (
        <p className="p-base">{message}</p>
      )
    }

    return null
  }

  const handleResults = () => (
    autocompleteState.collections && autocompleteState.collections.map(({ source, items }) => (
      <ResultsGroup
        key={source.sourceId}
        title={source.sourceId}
        source={source}
        items={items}
        autocomplete={autocomplete}
        components={components}
      />
    ))
  )

  return (
    <AutocompleteContainer>
      <Form
        ref={formRef}
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
        action={null}
      >
        <SearchInput
          ref={inputRef}
          onClose={handleClose}
          closeButtonAriaLabel={closeButtonAriaLabel}
          rounded={rounded}
          {...autocomplete.getInputProps({ inputElement: inputRef.current })}
        />
      </Form>
      <SearchResults
        ref={panelRef}
        rounded={rounded}
        active={autocompleteState.isOpen || !!autocompleteState.query}
        {...autocomplete.getPanelProps({})}
      >
        <div role="status" aria-atomic="true">
          {resultsCount()}
        </div>
        {handleResults()}
      </SearchResults>
    </AutocompleteContainer>
  )
}

export default AutoComplete
