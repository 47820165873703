import { NextApiResponse } from 'next'
import { ErrorCode, ErrorData } from '../types/api'

export const errorMessage = (err: unknown): string => {
  if (typeof err === 'string') return err

  if (err instanceof Error) return err.message

  return JSON.stringify(err)
}

export const toErrorObject = (err: unknown): { code?: ErrorCode, message: string } => ({
  ...(!!err && (typeof err === 'object') && ('code' in err) && (typeof err.code === 'number') && (Object.values(ErrorCode).includes(err.code)) ? { code: err.code } : {}),
  message: errorMessage(err),
})

export const errorResponse = ({
  res,
  message,
  status = 500,
  code,
}: {
  res: NextApiResponse,
  message: string,
  status?: number
  code?: ErrorCode,
}) => (
  res.status(status).json({ errors: [{ code, message }] })
)

export const isErrorData = (data: unknown): data is ErrorData => (
  !!data && (typeof data === 'object') && ('errors' in data) && Array.isArray(data.errors)
)

export const isUserObject = (user: unknown): user is { sub: string } => (
  !!user && (typeof user === 'object') && ('sub' in user) && (typeof user.sub === 'string')
)

export const isMfaEnabled = (user: unknown): boolean => {
  if (!!user && (typeof user === 'object') && ('phone_number_verified' in user) && (typeof user.phone_number_verified === 'string')) {
    return user.phone_number_verified === 'true'
  }

  return false
}

export const sessionNotValidMessage = ():string => 'Your session is not valid'

export const notLoggedInErrorMessage = ():string => 'You are not logged in'

export const mfaNotEnabledErrorMessage = ():string => 'You have to set up MFA to view your results'
