import { gtmEvent, events } from '../../gtm'

const createAlgoliaGTMPlugin = ({
  selectEvent,
  activeEvent,
}) => ({
  subscribe({ onSelect, onActive }) {
    onSelect(selectEvent)
    onActive(activeEvent)
  },
})

export const algoliaGTMPlugin = createAlgoliaGTMPlugin({
  selectEvent: ({ item }) => {
    gtmEvent({
      ...events.SEARCH_CLICK,
      label: item.objectID,
    })
  },
  activeEvent: ({ item }) => {
    gtmEvent({
      ...events.SEARCH_ACTIVE,
      label: item.objectID,
    })
  },
})

export default createAlgoliaGTMPlugin
