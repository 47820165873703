import { Notifier } from '@airbrake/browser'
import localNotifier from './local-notifier'

const AIRBRAKE_PROJECT_ID = process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_ID
const AIRBRAKE_PROJECT_KEY = process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT_KEY
const environment = process.env.NODE_ENV

const airbrake = ['qa', 'production'].includes(environment) ? new Notifier({
  projectId: AIRBRAKE_PROJECT_ID,
  projectKey: AIRBRAKE_PROJECT_KEY,
  environment,
}) : localNotifier

export default airbrake
